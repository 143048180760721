.post__overview {
	@include container();
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
	padding: $padding-base-vertical * 2 0;

	&-headline {
		font-size: ms(3);
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 20px auto;
			display: block;
		}
	}

	.post__title {
		font-weight: 500;
		font-size: ms(2);
		margin: $padding-base-vertical / 2 0;

		a {
			color: $text-color;
		}
	}
}

.post__item,
.events__item {
	@include span(12 of 12);
	margin-bottom: $padding-base-vertical * 4;

	@include breakpoint($small) {
		@include span(6 of 12);
		margin-bottom: $padding-base-vertical * 4;
	}
}

.post__summary {
	p {
		font-size: ms(0);
	}
}

.post__meta {
	time {
		font-size: ms(0);
		color: $gray-light;
	}
}

.post-categories {
	display: inline-block;
	padding: 0;
	list-style: none;
	margin: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 500;

	li {
		display: inline;

		a {
			color: $brand-secondary;
		}
	}
}

.navigation.pagination {
	@include container();
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
	padding: $padding-base-vertical * 2 0;
}

.post-type-archive-termine {
	.main {
		@include container();
		max-width: 1000px;
		margin: 0 20px;

		@include breakpoint($small) {
			margin: 0 auto;
		}
	}

	.termine {
		@include breakpoint($medium) {
			@include span(6 of 12);
		}
	}

	.navigation {
		@include container();
		@include span(12 of 12);
		margin-top: $padding-base-vertical * 4;
		margin-bottom: $padding-base-vertical * 4;

		& .prev,
		& .next {
			@include span(6 of 12);

			a {
				color: $brand-secondary;
			}
		}

		& .prev {
			float: right;
			text-align: right;
		}
	}
}

.events__date--passed {
	color: $gray-light;
}

.flexbox {
	.post__overview {
		display: flex;
		flex-wrap: wrap;
	}

	.post-type-archive-termine .main {
		display: flex;
		flex-wrap: wrap;
	}
}

.cli-plugin-main-link {
	margin-left: 0;
}

.cta--boxed .cta__text img.large-image {
	max-width: 250px;
}

.wp-caption.alignleft img {
	float: left;
	display: block;
	margin: 0;
	padding-right: 1em;
}

.wp-caption.alignright img {
	float: right;
	display: block;
	margin: 0;
	padding-left: 1em;
}

.wp-caption.alignleft .wp-caption-text {
	display: block;
	margin-right: 0;
	float: left;
	clear: left;
	padding-right: 1em;
	padding-bottom: 0;
}

.wp-caption.alignright .wp-caption-text {
	display: block;
	margin-right: 0;
	float: right;
	clear: right;
	padding-left: 1em;
	padding-bottom: 0;
}

.flexbox .wp-caption.alignleft,
.flexbox .wp-caption.alignright {
	display: block;
	width: 100% !important;
	padding-left: 1em;
	padding-right: 1em;
	margin-bottom: 0;
}

.form_wrapper.gform_wrapper .gfield_checkbox input[type="radio"] + label:before,
.form_wrapper.gform_wrapper .gfield_radio input[type="radio"] + label:before {
	margin-right: 0.5em;
}

.form_wrapper.gform_wrapper .gfield_checkbox input[type="radio"] + label:after,
.form_wrapper.gform_wrapper .gfield_radio input[type="radio"] + label:after {
	content: "";
	position: absolute;
	cursor: pointer;
	left: 6px;
	display: block;
	height: 8px;
	width: 8px;
	top: 9px;
	background: #282a6d;
	border-radius: 100%;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	opacity: 0;
}

.newsletter__link {
	margin-top: 20px;
	display: table-cell;
	vertical-align: middle;
	font-size: 12px;
}

@media screen and (max-width: 48em) {
	.newsletter__link {
		display: block;
		text-align: center;
	}

	.newsletter__link:hover,
	.newsletter__link:active,
	.newsletter__link:focus {
		text-decoration: none;
	}
}

.icon-mail_outline {
	display: block;
	font-size: 24px;
	margin-bottom: 5px;
}
