// -------
// Infobox
// -------

.infobox {
	position: relative;
	padding: $padding-base-vertical;

	@include breakpoint($small) {
		padding: $padding-base-vertical * 2;
		padding-top: $padding-base-vertical * 3;
	}

	&__container {
		@include container();
		position: relative;
	}

	&__larger span.s1 {
		font-size: ms(3);
	}

	&__text {
		position: relative;
		margin: 0 auto;

		&--left {
			background-color: $white;
			border: 2px solid $off-white;
		}

		&--right {
			background-color: $off-white;
		}

		@include breakpoint($small) {
			width: span(6 of 12);
			margin: 0 gutter(6);
		}

		a {
			color: $text-color;
		}

		p:has(img.aligncenter) {
			display: flex;
			flex-direction: column;

			img {
				margin: 0 auto;
			}
		}
	}

	&__background {
		padding: $padding-base-vertical * 2;
		width: 100%;
	}
}

@include breakpoint($small) {
	.flexbox {
		.infobox {
			&__container {
				display: flex;
				align-items: stretch;
			}

			&__text {
				display: flex;
				align-items: stretch;
			}

			&__background {
			}
		}
	}
}
