* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

// [aria-hidden="true"] {
// 	visibility: hidden;
// }

html {
	-webkit-tap-highlight-color: rgba($gray-base, 0);
}

body {
	@include font-smoothing;
	font-size: ms(0);
	font-family: $font-family-base;
	line-height: $line-height-base;
	color: $text-color;
	background-color: $body-bg;
}

p {
	@include typi($typi);
}

img {
	max-width: 100%;
	height: auto;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

// Links

a {
	color: $link-color;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus {
		@include tab-focus;
	}
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: 0;
	outline: 0;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

// Embeds responsive
//
// Credit: Nicolas Gallagher and SUIT CSS.

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	.embed-responsive-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
	padding-bottom: 75%;
}

.error404 .page-header {
	display: none;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
	cursor: pointer;
}

::selection {
	background: $brand-primary;
	color: $white;
}

.main {
	@include container();
}

.overlay {
	background: rgba($brand-primary, 1);
	background: -moz-linear-gradient(
		left,
		rgba($brand-primary, 1) 0%,
		rgba($brand-secondary, 1) 100%
	);
	background: -webkit-gradient(
		left top,
		right top,
		color-stop(0%, rgba($brand-primary, 1)),
		color-stop(100%, rgba($brand-secondary, 1))
	);
	background: -webkit-linear-gradient(
		left,
		rgba($brand-primary, 1) 0%,
		rgba($brand-secondary, 1) 100%
	);
	background: -o-linear-gradient(
		left,
		rgba($brand-primary, 1) 0%,
		rgba($brand-secondary, 1) 100%
	);
	background: -ms-linear-gradient(
		left,
		rgba($brand-primary, 1) 0%,
		rgba($brand-secondary, 1) 100%
	);
	background: linear-gradient(
		to right,
		rgba($brand-primary, 1) 0%,
		rgba($brand-secondary, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#282a6d', endColorstr='#8a2e23', GradientType=1 );
}

// Global Styles for the contentmodules
div#ewd-feup-login {
	max-width: 300px;
	margin: 0 auto;
	text-align: left;
}

.ewd-feup-text-input {
	margin-left: 0;
	width: 100%;
}

label[for="Submit"] {
	display: none !important;
}

.feup-pure-form-aligned .feup-pure-control-group label {
	text-align: left;
	padding: 10px 0;
}

.feup-pure-button-primary,
.feup-pure-button-selected,
a.feup-pure-button-primary,
a.feup-pure-button-selected {
	@extend %btn--primary;
}

.feup-information-div {
	max-width: 800px;
	margin: 0 auto;
	text-align: center;
	font-size: 1.5em;
}
