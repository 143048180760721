.footer {
	position: relative;
	padding: $padding-base-vertical * 4 0;
	background-color: $footer-bg;

	&__logo {
		margin-bottom: $padding-base-vertical * 2;
	}

	&__logo img {
		max-height: 80px;
		width: auto;
		object-fit: contain;
	}

	.container {
		@include container();
		width: span(10 of 12);
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		row-gap: 2rem;
		column-gap: 1rem;

		& > section:first-child {
			grid-column: span 2 / span 2;
		}

		@include breakpoint($small) {
			grid-template-columns: repeat(3, minmax(0, 1fr));

			& > section:first-child {
				grid-column: span 3 / span 3;
			}

			.widget_nav_menu {
				width: 100%;
			}
		}

		@include breakpoint($medium) {
			display: flex;

			& > section:first-child {
				flex-shrink: 0;
				width: 20%;
			}
		}

		@include breakpoint($large) {
			& > section:first-child {
				flex-shrink: 0;
				width: 25%;
			}
		}
	}

	.widget_text {
		.textwidget {
			a {
				@extend %btn--secondary-small;
				display: block;
				margin-top: 20px;
				text-align: center;
				border-radius: 0;
				text-transform: uppercase;
				font-weight: 400;
			}
		}
	}

	.widget_nav_menu,
	.widget_text {
		@include span(12 of 12);
		font-size: ms(0);

		h3 {
			font-size: ms(0);
			margin-bottom: $padding-base-vertical / 2;
		}

		p {
			font-size: ms(0);
			font-weight: 400;
		}

		.menu {
			list-style: none;
			margin: 0;
			padding: 0;
			font-weight: 400;
		}

		.menu-item {
			font-size: 14px;

			a {
				color: $text-color;
			}

			@include breakpoint($small) {
				font-size: 16px;
			}
		}

		.sub-menu {
			list-style: none;
			padding-inline-start: 1rem;

			.menu-item {
				font-size: ms(-2);
			}
		}

		@include breakpoint($small) {
			@include span(2 of 12);

			h3 {
				margin-top: 0;
			}

			.sub-menu {
				padding-inline-start: 1.5rem;

				.menu-item {
					font-size: ms(-2);
				}
			}
		}
	}

	&__copyright {
		padding: $padding-base-vertical 0;
		color: $white;
		background: rgba($brand-secondary, 1);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		column-gap: 1em;
		row-gap: 0.25em;

		p {
			text-align: center;
			font-size: ms(-1);
			margin: 0;

			&:first-child {
				width: 100%;
			}

			a {
				color: $white;
				font-weight: 700;

				&:hover {
					color: $white;
					text-decoration: underline;
				}
			}
		}

		.first-divider {
			display: none;
		}

		@include breakpoint($small) {
			row-gap: 1em;
			flex-wrap: nowrap;

			.first-divider {
				display: block;
			}

			p:first-child {
				width: auto;
			}
		}
	}

	&__decoration {
		@include container();
		height: 100%;
		margin-top: $padding-base-vertical * 4;
	}

	&__svg {
		fill: $footer-bg;
		width: 100%;
		height: 100%;
		display: block;
		top: 2px;
		position: relative;
	}

	&__stores {
		@include container();
		margin-top: $padding-base-vertical * 3;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	&__store {
		display: inline-block;
		overflow: hidden;
		width: 165px;
		height: 40px;
		text-align: center;
		margin-bottom: $padding-base-vertical;
		vertical-align: middle;

		img {
			height: 100%;
			width: auto;
		}
	}
}
