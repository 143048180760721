.restricted__content {
	padding-top: $padding-base-vertical * 2;
	padding-bottom: $padding-base-vertical * 2;
}

.latest-posts {
	@include container();

	&__wrapper {
		@include span(12 of 12);
		padding-left: 0;
		padding-right: 0;
		margin-top: $padding-base-vertical * 3;
	}

	h2 {
		text-align: center;
	}

	&__excerpt {
		margin-bottom: $padding-base-vertical;

		p {
			font-size: ms(-1);
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: pre-wrap;
			margin-top: 0;

			@include breakpoint($medium) {
				font-size: ms(0);
			}
		}
	}

	&__list {
		height: 100%;
		margin-top: $padding-base-vertical * 4;
	}

	.slider__container {
		@include container();
		@include span(12 of 12);
		padding-right: 0;
		padding-left: 0;
		position: relative;
	}

	.slider {
		@include container();
		position: relative;
		height: 400px;

		@include breakpoint($small) {
			height: 100%;
		}

		.slide {
			@include container();
			position: relative;

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($brand-secondary, 0.6);
				pointer-events: none;

				content: "";
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				aspect-ratio: 4 / 3;

				@include breakpoint($small) {
					aspect-ratio: auto;
				}
			}
		}
	}

	.slideshow__content {
		position: absolute;
		height: 100%;
		top: 0;
		text-align: left;
		z-index: 11;
		padding: 2rem;
		transition: background-color 0.3s ease-in-out;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include breakpoint($small) {
			padding: 3rem 0;
			width: 83.3333333333%;
		}

		@include breakpoint($medium) {
			padding: 4rem 0;
		}
	}

	.slideshow__headline,
	.slideshow__subtitle {
		color: $white;
		font-weight: 500;
		width: auto;

		a {
			color: $white;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.slideshow__headline {
		margin-top: 0;
		text-transform: uppercase;
		margin-bottom: 0;
		font-size: ms(4);
		text-align: left;
		line-height: 1.125;
		margin-bottom: 0.25em;

		@include breakpoint($small) {
			font-size: ms(7);
		}

		@include breakpoint($large) {
			font-size: ms(10);
			max-width: 20ch;
		}
	}

	.slideshow__subtitle {
		margin-top: 0;
		font-weight: 400;
		font-size: ms(0);
		margin-bottom: 0;
		max-width: 30ch;

		@include breakpoint($small) {
			font-size: ms(3);
		}

		@include breakpoint($large) {
			font-size: ms(5);
		}
	}

	.slideshow__link-container {
		width: span(12 of 12);
		margin: 0 auto;
		margin-top: $padding-base-vertical * 2;
	}

	.slideshow__link-text {
		@extend %btn--white;

		font-size: ms(-2);

		&:hover {
			text-decoration: none;
			background-color: $white;
		}

		&:focus {
			outline: 0;
			text-decoration: none;
			color: $white;
		}

		@include breakpoint($medium) {
			font-size: ms(0);
		}
	}

	.latest-posts__listitem {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 15px;
		padding-bottom: 15px;
		width: 100%;
	}

	.latest-posts__title {
		font-size: ms(0);
		margin-top: 0;
		margin-bottom: 0;
		min-height: 3rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 20ch;

		@include breakpoint($small) {
			margin-bottom: 1em;
		}

		@include breakpoint($medium) {
			font-size: ms(2);
		}
	}

	.latest-posts__link {
		font-size: ms(0);
		margin-top: auto;
	}

	.lSSlideOuter,
	.lSSlideWrapper {
		height: 100%;
		min-height: 200px;
		aspect-ratio: 4 / 3;

		@include breakpoint($small) {
			min-height: 500px;
			max-height: 650px;
			aspect-ratio: auto;
		}
	}

	.lSSlideOuter {
		@include breakpoint($small) {
			.slide {
				display: block;
				height: 100%;
				float: left;
				position: relative;
				max-height: 650px;

				img {
					min-height: 100%;
					min-width: 100%;
					width: auto;
					height: auto;
					max-width: none;
					display: block;
				}
			}
		}
	}
}

.cta_button {
	position: absolute;
	z-index: 11;
	right: $padding-base-vertical;
	bottom: $padding-base-vertical;
	width: 90px;
	height: 90px;
	border-radius: 100%;
	background-color: $white;
	text-align: center;
	display: table;
	overflow: hidden;

	&__link {
		display: table-cell;
		vertical-align: middle;
		text-transform: uppercase;

		span {
			font-size: 8px;
			width: 100%;
			display: block;
		}

		.icon-phone {
			font-size: 24px;
			margin-bottom: 5px;
		}

		&:hover {
			text-decoration: none;
		}
	}

	@include breakpoint($small) {
		padding: $padding-base-vertical;
		right: $padding-base-vertical * 2;
		bottom: $padding-base-vertical * 2;
		width: 200px;
		height: 200px;

		.icon-phone {
			font-size: 36px;
			margin-bottom: 10px;
		}

		span {
			font-size: 10px;
			width: 100%;
			display: block;
		}
	}
}

.flexbox {
	.latest-posts__list {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));

		@include breakpoint($small) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include breakpoint($medium) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.latest-posts__listitem {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
	}

	.latest-posts {
		display: flex;
		flex-direction: column;

		&__thumbnail {
			aspect-ratio: 4 / 3;
			margin-bottom: 1rem;

			img  {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.latest-posts__wrapper {
		order: 2;
	}

	.slider__container {
		order: 1;
	}

	@include breakpoint($small) {
		.latest-posts__list {
			flex-direction: row;
		}
	}
}

.slider__container .lSAction > a {
	width: 0.75rem;
	height: 0.75rem;
	opacity: 1;
	border-top: $white 2px solid;

	@include breakpoint($small) {
		width: 1rem;
		height: 1rem;
	}

	&.lSPrev {
		transform: rotate(-45deg);
		border-left: $white 2px solid;
		left: 0.75rem;

		@include breakpoint($small) {
			left: 1.5rem;
		}
	}

	&.lSNext {
		transform: rotate(45deg);
		border-right: $white 2px solid;
		right: 0.75rem;

		@include breakpoint($small) {
		}
	}
}

.termine-template-default .entry-title {
	display: none;
}

.termine {
	@include container();
	padding-top: 40px;
	padding-bottom: 40px;

	.content__container,
	.entry-content {
		padding: $padding-base-vertical;
		padding-bottom: $padding-base-vertical * 2;

		p {
			font-size: ms(0);
		}
	}

	.entry-content {
		max-width: 800px;
		margin: 0 auto;

		[style*="36pt"] {
			font-size: 2rem !important;

			@include breakpoint($small) {
				font-size: 36pt !important;
			}
		}
	}

	.content__container {
		max-width: 400px;
		margin: 0 auto 0 0;

		@include breakpoint($large) {
			margin: 0 auto;
		}
	}

	.download {
		clear: both;
	}

	.entry-title {
		font-size: ms(2);
		color: $brand-secondary;
		font-weight: 500;
		margin-top: $padding-base-vertical;
	}

	strong {
		font-weight: 600;
	}

	.content__left {
		@include span(12 of 12);

		@include breakpoint($medium) {
			@include span(4 of 12);
		}
	}

	.content__right {
		@include span(12 of 12);

		@include breakpoint($medium) {
			@include span(8 of 12);

			border-left: $black solid 2px;
		}
	}
}

.related_posts,
.related_events,
.related_projects {
	position: relative;
	padding: $padding-base-vertical * 2 0;

	&__container {
		@include container();
		max-width: 1000px;
		margin: 0 auto;
	}

	&__headline {
		font-size: ms(3);
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;
		position: relative;
		margin-bottom: $padding-base-vertical * 2;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 20px auto;
			display: block;
		}
	}
}

.related_posts {
	.related_post {
		@include breakpoint($medium) {
			@include span(6 of 12);
		}

		.post__title {
			font-weight: 500;
			font-size: ms(2);
			margin: $padding-base-vertical / 2 0;

			a {
				color: $brand-secondary;
			}
		}

		.post__content {
			p {
				font-size: ms(0);
			}
		}

		.post__detail {
			color: $gray-light;
			font-weight: 500;
		}
	}
}

.related_projects {
	.related_project {
		@include breakpoint($medium) {
			@include span(6 of 12);
		}

		.project__title {
			font-weight: 500;
			font-size: ms(2);
			margin: $padding-base-vertical / 2 0;

			a {
				color: $brand-secondary;
			}
		}

		.project__content {
			p {
				font-size: ms(0);
			}
		}

		.project__detail {
			color: $gray-light;
			font-weight: 500;
		}
	}
}

.related {
	&__link-container {
		text-align: center;
		margin-top: $padding-base-vertical * 2;
	}

	&__link-button {
		@extend %btn--secondary;
		text-transform: uppercase;
		font-weight: 400;
	}
}

.related_events {
	.related_event {
		@include breakpoint($medium) {
			@include span(6 of 12);
		}
	}
}

.flexbox {
	.related_event,
	.termine .related_post .post__content {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.events__meta {
		order: 1;
	}

	.events__title,
	.termine .post__title {
		order: 2;
	}

	.events__detail {
		order: 3;
	}
}

.search-results {
	.main {
		max-width: 800px;
		margin: 2em auto;
	}

	.search-title {
		color: $gray-light;
		font-weight: 500;
		margin-top: 0;
	}

	article {
		margin-top: $padding-base-vertical * 2;
	}

	header {
		.entry-title {
			margin-bottom: 0;
		}

		.entry-title a {
			color: $brand-secondary;
			font-weight: 400;
			font-size: ms(1);
			margin-bottom: 0;
		}
	}

	.entry-summary {
		p {
			font-size: ms(0);
		}
	}
}

.recentposts {
	margin-top: $padding-base-vertical * 2;
	margin-bottom: $padding-base-vertical * 2;

	@include breakpoint($small) {
		margin-top: $padding-base-vertical * 4;
		margin-bottom: $padding-base-vertical * 4;
	}
}

.recent_post {
	margin-bottom: $padding-base-vertical * 2;

	@include breakpoint($small) {
		margin-bottom: $padding-base-vertical * 4;
	}
}

.related_posts .related_post .recent_posts__title.post__title {
	margin-bottom: 0;
}

.post__content p {
	margin-top: $padding-base-vertical / 2;
}

.recent_posts__permalink {
	font-weight: 500;
	color: $gray-light;
}

.related_posts .termine .recent_posts__title a {
	color: $text-color;
}

.error404 {
	.alert {
		top: 80px;
	}
}

.error-content {
	margin-top: $padding-base-vertical * 4;
	margin-bottom: $padding-base-vertical * 4;
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;

	h1,
	h2 {
		font-weight: 500;
		color: $gray-light;
		margin: 0;
	}

	a {
		margin-top: $padding-base-vertical;
		display: block;
	}
}
