// ---------
// Textblock
// ---------

.textblock {
	@include container();
	padding: $padding-base-vertical * 2 0;
	position: relative;

	@include breakpoint($small) {
		padding: $padding-base-vertical * 2 0;
	}

	@include breakpoint($medium) {
		padding: $padding-base-vertical * 4 0;
	}

	&--white {
		background-color: $white;
	}

	&--gray {
		background-color: $off-white;
	}

	&--large {
		padding-bottom: $padding-base-vertical 0;
		font-size: ms(1);
	}

	&--small {
		font-size: ms(-1);
	}

	&--columns {
		clear: both;
		max-width: 1200px;

		.textblock__right {
			margin-top: 3rem;

			@include breakpoint($medium) {
				margin-top: 0;
			}
		}

		.textblock__left,
		.textblock__right {
			@include breakpoint($medium) {
				@include span(6 of 12);
			}
		}
	}

	&__headline {
		text-align: center;
	}

	h1,
	h2,
	h3,
	h4 {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 20px auto;
			display: block;
		}
	}

	h3,
	h4 {
		color: $gray-light;
		font-weight: 500;
		margin-top: 0;
		margin-bottom: $padding-base-vertical * 2;
	}

	table {
		max-width: 800px;
		margin: 0 auto;
	}

	p,
	ul {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		font-weight: 400;
		margin-top: 0;
		max-width: 800px;
		padding-left: gutter(12);
		padding-right: gutter(12);
		margin-bottom: $padding-base-vertical;

		@include breakpoint($small) {
			margin-bottom: $padding-base-vertical * 2;
		}

		&:last-child {
			margin-bottom: 0;

			.textblock__button {
				margin-bottom: 0;
			}
		}
	}

	img {
		margin-right: auto;
		margin-left: auto;
		display: block;
		height: auto;
		max-width: 100%;
		min-height: 1px;

		&.alignleft {
			float: left;
			margin: 0;
			padding-right: $padding-base-vertical;
			padding-bottom: $padding-base-vertical;
		}

		&.alignright {
			float: right;
			margin: 0;
			padding-left: $padding-base-vertical;
			padding-bottom: $padding-base-vertical;
		}
	}

	&__stores {
		@include container();
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	&__column {
		@include span(12 of 12);
		padding: $padding-base-vertical * 2;

		@include breakpoint($small) {
			@include span(6 of 12);
		}

		&--grey {
			background-color: $off-white;
		}
	}

	&__store {
		display: inline-block;
		overflow: hidden;
		width: 309px;
		height: 75px;
		text-align: center;

		&--apple {
			background-size: 309px 75px !important;
		}

		img {
			height: 100%;
			width: auto;
		}
	}

	.wp-caption {
		max-width: 800px;
		margin-right: auto;
		margin-left: auto;

		&-text {
			// text-transform: uppercase;
			text-align: left;
			letter-spacing: 0;
			font-weight: 400;
			max-width: 800px;
			margin-right: auto;
			margin-left: 0;
			padding-top: $padding-base-vertical;
			padding-bottom: $padding-base-vertical * 2;
		}
	}

	.textblock__button {
		margin-bottom: $padding-base-vertical * 3;
		display: block;

		a {
			@extend %btn--secondary;
		}

		&--outline {
			a {
				@extend %btn--secondary-outline;
			}
		}

		h3 {
			margin-top: $padding-base-vertical * 2;
		}
	}
}

.flexbox {
	.wp-caption {
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			display: flex;
			order: 1;
		}

		.wp-caption-text {
			display: flex;
			order: 2;
		}
	}
}

.feature--photo + .textblock--gray {
	@include breakpoint($small) {
		margin-top: 2em;
	}
}

.textblock--gray.textblock--columns {
	margin: 0;
	width: 100%;
	max-width: 100%;

	.textblock__left p {
		margin-left: auto;
		margin-right: 0;
		max-width: 570px;
	}

	.textblock__right p {
		margin-right: auto;
		margin-left: 0;
		max-width: 570px;
	}
}
